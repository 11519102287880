function load(component, folder) {
  // '@' is aliased to src/components
  if (folder) {
    return () =>
      import(
        /* webpackChunkName: "[request]" */ `@/pages/${folder}/${component}.vue`
      );
  }
  return () =>
    import(/* webpackChunkName: "[request]" */ `@/pages/${component}.vue`);
}

export default [
  {
    path: "/",
    component: load("Template"),
    // redirect: '/order',
    children: [
      {
        path: "",
        redirect: "/order"
      },
      {
        path: "profile",
        name: "profile",
        component: load("Profile")
      },
      {
        path: "/profile/changepassword",
        name: "changepassword",
        component: load("ChangePassword")
      },
      {
        path: "settings",
        name: "settings",
        component: load("Settings")
      },
      {
        path: "notifications",
        name: "notifications",
        component: load("Notifications")
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: load("Login")
  },
  {
    path: "/logout",
    name: "logout",
    component: load("Logout")
  },
  {
    path: "/register",
    name: "registernewuser",
    component: load("Register")
  },
  {
    path: "/resetpassword",
    name: "resetpassword",
    component: load("ResetPassword")
  },
  {
    path: "/order",
    component: load("Template"),
    children: [
      {
        path: "",
        name: "myOrders",
        component: load("My", "Order")
      },
      {
        path: "all",
        name: "allorders",
        component: load("All", "Order")
      },
      {
        path: "new",
        name: "neworder",
        redirect: "/order/new/1"
        // component: load('New', 'Order')
      },
      {
        path: "new/1",
        name: "stepOne",
        component: load("StepOne", "Order")
      },
      {
        path: "new/2",
        name: "stepTwo",
        component: load("StepTwo", "Order")
      },
      {
        path: "new/3",
        name: "stepThree",
        component: load("StepThree", "Order")
      },
      {
        path: "lines",
        component: load("Lines", "Order")
      },
      {
        path: "shared/:id",
        component: load("OrderSharedView", "Order")
      },
      {
        path: "summary",
        component: load("Summary", "Order")
      },
      {
        path: "view/:id",
        component: load("OrderView", "Order")
      },
      {
        path: "edit/:id",
        component: load("OrderEdit", "Order")
      },
      {
        path: "history/:id",
        component: load("History", "Order")
      }
    ]
  },
  {
    path: "/admin",
    name: "admin",
    meta: { requiresAdmin: true },
    component: load("Admin", "Admin"),
    children: [
      {
        path: "configuration",
        name: "configuration",
        component: load("Configuration", "Admin")
      },
      {
        path: "companies",
        name: "companies",
        component: load("Companies", "Admin")
      },
      {
        path: "users",
        name: "users",
        component: load("Users", "Admin")
      },
      {
        path: "newuser",
        name: "newuser",
        component: load("NewUser", "Admin")
      },
      {
        path: "projects",
        name: "projects",
        component: load("Projects", "Admin")
      },
      {
        path: "project/:id",
        name: "project",
        component: load("Project", "Admin")
      },
      {
        path: "items",
        component: load("Item", "Item"),
        children: [
          {
            path: "all",
            name: "items",
            component: load("Items", "Item")
          },
          {
            path: "new",
            name: "itemnew",
            component: load("ItemNew", "Item")
          },
          {
            path: "edit/:id",
            name: "itemedit",
            component: load("ItemEdit", "Item")
          },
          {
            path: "metadata",
            name: "metadata",
            component: load("MetaData", "Item")
          },
          {
            path: "metadata/new",
            name: "NewMetaData",
            component: load("NewMetaData", "Item")
          }
        ]
      }
    ]
  }

  // {
  //   name: 'PageNotFound',
  //   path: '*',
  //   redirect: '/'
  //   // component: load('PageNotFound')
  //   }
];
