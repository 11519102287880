import axios from "axios";
import config from "@/store/config.js";

const state = {
  isLoading: true,
  loadedActivities: 0,
  activities: [],
  error: null
};

const getters = {
  getActivities: state => state.activities
};

const actions = {
  async loadActivities(context, skip) {
    context.commit("setLoading", true);
    return new Promise((resolve, reject) => {
      var url = `${config.API}/Activities`;
      if (skip) {
        url += `?skip=${skip}`;
      }

      axios
        .get(url)
        .then(r => {
          context.commit("setActivities", r.data);
          context.commit("setLoading", false);
          resolve(r);
        })
        .catch(e => {
          context.commit("setError", e);
          reject(e);
        });
    });
  }
};

const mutations = {
  setLoading: (state, isLoading) => (state.loading = isLoading),
  setActivities: (state, activityData) => (state.activities = activityData),
  setError: (state, error) => (state.error = error)
};

export default {
  state,
  getters,
  actions,
  mutations
};
