import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";
import store from "@/store/";

Vue.use(Router);

let router = new Router({
  routes,
  mode: "history",
  base: __dirname,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  //checking authentication
  // const accessToken = localStorage.getItem('access_token');
  const accessToken = store.getters.getToken; // || localStorage.getItem('access_token');
  if (accessToken) {
    //$store.autentication.isAuthenticated
    //already signed in, we can navigate anywhere
    if (to.matched.some(record => record.meta.requiresAdmin)) {
      //Checks if the routes has meta data requiresAdmin
      if (store.getters.getRole || store.getters.getRole === "Admin") {
        // console.log("Role = " + store.getters.getRole);
        next();
      } else {
        next({ path: "/" });
      }
    }
    next();
  } else if (
    //This need to be changed and added as parameters on the routes object
    to.path === "/login" ||
    to.path === "/register" ||
    to.path === "/resetpassword" ||
    to.path === "/404.html"
  ) {
    //no need for authentication

    next();
  } else {
    // autentication.dispatch('authenticate',(to.path)).then(() => {
    //     console.log('authenticating a protected url:' + to.path);
    // next();

    // }).then(function(){
    //   console.log("autenticating done.")
    //   next();
    // })
    // .catch(function(error) {
    //   console.log("failed to dispatch");
    //   router.push({ name: 'PageNotFound' });

    // });
    router.push("/login");
  }
  //  next();
});

export default router;
