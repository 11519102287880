//entry point for vuex
import Vue from "vue";
import Vuex from "vuex";
// import VuexPersistence from "vuex-persist";
import orders from "./modules/orders.js";
import orderhistory from "./modules/orderhistory";
import items from "./modules/items.js";
import autentications from "./modules/autentication.js";
import activities from "./modules/activities.js";
import settings from "./modules/settings.js";
import companies from "./modules/companies.js";

Vue.use(Vuex);

//plugin that makes Vuex use local storage
// const vuexLocal = new VuexPersistence({
//     storage: window.localStorage
// });

let store = new Vuex.Store({
  modules: {
    orders,
    orderhistory,
    autentications,
    items,
    activities,
    settings,
    companies
  }
  // plugins: [vuexLocal.plugin]
});

//Create store
export default store;
