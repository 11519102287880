import axios from "axios";
import config from "@/store/config.js";

const state = {
  isHistoryLoading: true,
  history: [],
  historyError: null
};

const getters = {
  getOrderHistory: state => state.history
};

const actions = {
  async loadOrderHistories(context, id) {
    context.commit("setLoadingHistory", true);
    return new Promise((resolve, reject) => {
      var url = `${config.API}/OrderHistory/${id}`;

      axios
        .get(url)
        .then(r => {
          context.commit("setOrderHistory", r.data);
          context.commit("setLoadingHistory", false);
          resolve(r);
        })
        .catch(e => {
          context.commit("setHistoryError", e);
          reject(e);
        });
    });
  },
  async addHistory(context, history) {
    const response = await axios.post(`${config.API}/OrderHistory`, {
      ...history
    });
    context.commit("newOrderHistory", response.data);
  }
};

const mutations = {
  setLoadingHistory: (state, isLoading) => (state.isHistoryLoading = isLoading),
  setOrderHistory: (state, historyData) => (state.history = historyData),
  setHistoryError: (state, error) => (state.historyError = error),
  newOrderHistory: (state, orderHistory) => state.history.unshift(orderHistory)
};

export default {
  state,
  getters,
  actions,
  mutations
};
