import axios from "axios";
import config from "@/store/config.js";

const state = {
  loading: true,
  error: null,
  userSettings: {
    lang: "en"
  },
  appSettings: {
    debug: false,
    useProjectNo: true,
    useProjectSearch: false,
    email: "kristoffer@risa.email",
    sendEmail: true,
    useExcelAttchement: false
  }
};

const getters = {
  isSettingsLoading: state => state.loading,
  getSettingsError: state => state.error,
  getUserSettings: state => state.settings,
  getAppSettings: state => state.appSettings
};

const actions = {
  async loadSettings(context) {
    context.commit("setLoading", true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.API}/SystemConfigurations/`)
        .then(r => {
          context.commit("setSettings", r.data);
          context.commit("setLoading", false);
          resolve(r);
        })
        .catch(e => {
          context.commit("setErrorMsg", e);
          reject(e);
        });
    });
  },

  async updateSettings(context) {
    context.commit("setLoading", true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${config.API}/SystemConfigurations/${state.appSettings.id}`, {
          ...state.appSettings
        })
        .then(r => {
          context.commit("setLoading", false);
          resolve(r);
        })
        .catch(error => {
          context.commit("setErrorMsg", error);
          reject(error);
        });
    });
  }
};

const mutations = {
  setLoading: (state, loading) => (state.loading = loading),
  setSettings: (state, settings) => (state.appSettings = settings),
  setErrorMsg: (state, error) => (state.error = error)
};

export default {
  state,
  getters,
  actions,
  mutations
};
