<template>
  <div v-cloak>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app"
};
</script>

<style>
[v-cloak] {
  display: none;
}
</style>
