import Vue from "vue";
import App from "@/App.vue";
import BootstrapVue from "bootstrap-vue";
import store from "@/store";
import router from "@/router/index.js";
import { i18n } from "@/plugins/i18n.js";
import axios from "axios";
// import serviceworker from "./services/registerServiceWorker";
import moment from "moment";
import numeral from "numeral";
// import numeralCofng from "@/plugins/numeralConfig.js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

// this need to be refactored out in a separated file
const appInsights = new ApplicationInsights({
  config: {
    // instrumentationKey: "cfbadc02-2b87-4703-9214-b7f952daf971" //dev
    instrumentationKey: "4d71d2d9-649b-4b7f-a959-3f890457c2c6" //prod
    /* ...Other Configuration Options... */
  }
});

appInsights.loadAppInsights();
appInsights.trackPageView();

Vue.use(BootstrapVue);

Vue.config.productionTip = false;

// date formating - needs to be refactored out in a separated file
Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

Vue.filter("defaultFormatDate", function(value) {
  if (value) {
    return moment(String(value)).format("YYYY-MM-DD");
  }
});

Vue.filter("formatLongDateTime", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY HH:mm");
  }
});

Vue.filter("formatNumber", function(value) {
  if (value) {
    return numeral(value).format("0.00"); // displaying other groupings/separators is possible, look at the docs
  }
});
// done with date formating

new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount("#app");

// axios interceptors for checking access token
axios.interceptors.request.use(config => {
  const accessToken = localStorage.access_token || null;
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

// axios interceptors for handling 401 status and logging user out
axios.interceptors.response.use(
  response => {
    return response;
  },
  function(error) {
    // Do something with response error
    if (error.response.status === 401) {
      // auth.logout();
      // router.replace('/auth/login');
      store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    }
    return Promise.reject(error.response);
  }
);
