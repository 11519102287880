import axios from "axios";
import config from "@/store/config.js";

const state = {
  token: localStorage.getItem("access_token") || null,
  user: JSON.parse(localStorage.getItem("user")) || {},
  loading: false,
  allUsers: []
};

const getters = {
  getToken: state => state.token,
  getRole: state => state.user.role,
  getLoadingFromAuthentication: state => state.loading,
  // isAuthenticated: state => {
  //   return state.token !== null
  // },
  getUser: state => state.user,
  getUsers: state => state.allUsers
};

const actions = {
  async updateUser(context, user) {
    //Update user process started
    context.commit("setLoadingAutentication", true);
    return new Promise((resolve, reject) => {
      if (user && user.id) {
        axios
          .put(`${config.AUTH}/${user.id}`, {
            ...user
          })
          .then(function(response) {
            context.commit("setUser", response.data);
            localStorage.setItem("user", JSON.stringify(response.data));
            resolve(response);
          })
          .catch(function(error) {
            reject(error);
          });
        context.commit("setLoadingAutentication", false);
      } else {
        context.commit("setLoadingAutentication", false);

        reject("mangler id.");
      }
    });
  },

  async loadUsers(context) {
    context.commit("setLoadingAutentication", true);
    return new Promise((resolve, reject) => {
      axios
        .get(config.AUTH)
        .then(r => {
          context.commit("setAllUsers", r.data);
          //Loaded users
          resolve(r);
          context.commit("setLoadingAutentication", false);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  // async authenticate({ commit }, returnPath) {

  //     try {
  //     let user = await mgr.getUser();
  //     if (!!user) {
  //       commit('setUser', user);
  //       commit('setIsAutenticated',true);
  //       commit('setLang', 'en'); //TODO: Set based on user perfernce
  //        // i18n.local = 'en';
  //       // } else {
  //       //   mgr.signinRedirect({ state: returnPath });
  //       }
  //   } catch (err) {
  //
  //   }
  // },

  // async signIn (returnPath) {
  //   returnPath ? mgr.signinRedirect({ state: returnPath })
  //       : mgr.signinRedirect();
  // },

  // async signOut () { //not implemented vue element and view for this yet
  //     mgr.events.addUserSignedOut(function () {
  //         mgr.signoutRedirect().then(function (resp) {
  //           commit('setUser', null); //clear user details in vuex
  //         }).catch(function (err) {
  //
  //         })
  //     })
  // },

  // new actions for new login UI
  async logIn(context, credentials) {
    //Sign in process started
    return new Promise((resolve, reject) => {
      context.commit("setLoadingAutentication", true);
      axios
        .post(`${config.AUTH}/Authenticate`, {
          username: credentials.username,
          password: credentials.password
        })
        .then(function(response) {
          context.commit("setToken", response.data.token);
          localStorage.setItem("access_token", response.data.token);
          context.commit("setUser", response.data);
          localStorage.setItem("user", JSON.stringify(response.data));
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
      context.commit("setLoadingAutentication", false);
    });
  },

  async logout(context) {
    return new Promise((resolve, reject) => {
      context.commit("setLoadingAutentication", true);
      if (context.state.token) {
        //Logging out
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        context.commit("removeToken");
        context.commit("removeUser");
        resolve(context);
      } else {
        reject(context);
      }
      context.commit("setLoadingAutentication", false);
    });
  },

  async forgotPassword(context, email) {
    return new Promise((resolve, reject) => {
      context.commit("setLoadingAutentication", true);
      //Forgot password function trigged
      axios
        .post(`${config.AUTH}/forgotpassword`, {
          email: email
        })
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
      resolve(context);
      context.commit("setLoadingAutentication", false);
    });
  }
};

const mutations = {
  setUser: (state, user) => (state.user = user),
  setToken: (state, token) => (state.token = token),
  removeUser: state => (state.user = null),
  removeToken: state => (state.token = null),
  setLoadingAutentication: (state, isLoading) => (state.loading = isLoading),
  setAllUsers: (state, users) => (state.allUsers = users)
};

export default {
  state,
  getters,
  actions,
  mutations
};
