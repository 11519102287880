import axios from "axios";
import config from "@/store/config.js";

const state = {
  isLoading: true,
  specialTxt: [],
  polishTypes: []
};

const getters = {
  getSpecialTxt: state => state.specialTxt,
  getPolishTypes: state => state.polishTypes
};

const actions = {
  async loadMetaData(context) {
    context.commit("setLoading", true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.API}/MetaData`)
        .then(r => {
          context.commit("setMetaDatas", r.data);
          context.commit("setLoading", false);
          resolve(r);
        })
        .catch(error => {
          context.commit("setErrorMsg", error);
          reject(error);
        });
    });
  },

  async createNewMetaData(context, metadata) {
    return new Promise((resolve, reject) => {
      if (metadata) {
        axios
          .post(`${config.API}/MetaData`, {
            ...metadata
          })
          .then(r => {
            context.commit("newItem", r.data);
            resolve(r);
          })
          .catch(error => {
            reject(error);
          });
      }
      resolve("missing");
    });
  }
};

const mutations = {
  setLoading: (state, isLoading) => (state.loading = isLoading),
  setMetaDatas: (state, data) => {
    if (data) {
      state.specialTxt = data.filter(x => x.metaDataType === "SpecialTxt");
      state.polishTypes = data.filter(x => x.metaDataType === "PolishType");
    }
  },
  setErrorMsg(state, msg) {
    if (msg) {
      state.error = msg;
    } else {
      state.error = null;
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
