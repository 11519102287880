const configs = {
  // API: "https://bestilling.creonordic.no:5005/api",
  API: "https://api.risa.consulting/api",
  // API: 'https://localhost:5005/api',
  // AUTH: "https://bestilling.creonordic.no:5000/Users"
  AUTH: "https://auth.risa.consulting/Users"
  // AUTH: 'https://localhost:5001/Users'
};

export default configs;
