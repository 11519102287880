import axios from "axios";
import config from "@/store/config.js";

const state = {
  loading: false,
  editOrderLineId: null,
  statuses: [],
  projects: [],
  project: {
    name: "TBD",
    contactDetails: {
      id: "",
      name: "",
      customer: ""
    }
  },
  contactDetails: {
    baseDomainEvents: [],
    name: "",
    mail: "",
    phone: "",
    address: "",
    id: ""
  },
  contactOptions: [{ value: null, text: "Missing data" }],
  order: {
    description: "",
    dueDate: "0001-01-01",
    shippingDate: "0001-01-01",
    deliveryDate: "0001-01-01",
    receivedDate: "0001-01-01",
    preferredDeliveryDate: "0001-01-01",
    deliveryAddress: {
      streetName: "",
      postalCode: "",
      city: "",
      country: "",
      latitude: null,
      longitude: null
    },
    notes: null,
    orderLines: []
  },
  orderLine: {
    name: "",
    description: "",
    qty: "1",
    height: null,
    width: null,
    sqrMeter: NaN,
    specialTxt: "",
    polishType: ""
  },
  orders: [],
  items: [],
  currentItem: {},
  loadingItemsOnOrder: true,
  uploadError: null,
  error: {
    hasError: false,
    msg: ""
  },
  status: {
    item: null
  },
  deleteItem: {
    showModal: false,
    isDelete: false,
    msg: ""
  },
  selectedItem: {
    id: "",
    baseDomainEvents: [],
    name: "test",
    partenItemId: "00000000-0000-0000-0000-000000000000",
    children: null,
    itemNo: 0,
    description: null,
    note: null,
    itemType: null,
    specialTxt: null,
    polishType: null,
    supplier: null,
    itemMetaData: null,
    isActive: true,
    isDeleted: false
  },
  polishTypePerItem: {},
  polishTypesLoading: true
};

const getters = {
  getUploadError: state => state.uploadError,
  isLoading: state => state.loading,
  allProjects: state => state.projects,
  loadingProjects: state => state.loading,
  getCurrentProject: state => state.project,
  getContactDetails: state => state.contactDetails,
  getContactDetailsOptions: state => state.contactOptions,
  getAllItems: state => state.items,
  getOrderNo: state => state.orderNo,
  getOrders: state => state.orders,
  getOrderStatus: state => {
    if (state.order && state.order.status) {
      return state.order.status;
    } else {
      return "";
    }
  },
  getLoadingState: state => state.loadingItemsOnOrder,
  getCurrentItem: state => state.currentItem,
  getCurrentOrder: state => state.order,
  getCurrentOrderLine: state => state.orderLine,
  getCurrentContactDetails: state => state.contactDetails,
  getItemInOrderStatus: state => state.status.item,
  allItems: state => state.items,
  getLoadingItemState: state => state.loading,
  getItem: state => state.selectedItem,
  getErrorMsg: state => state.error,
  getDeleteItem: state => state.deleteItem,
  getStatuses: state => state.statuses,
  getEditorOrderLineId: state => state.editOrderLineId,
  getPolishTypesForItem: state => {
    return state.polishTypePerItem;
  },
  getPolishTypesLoading: state => state.polishTypesLoading
};

const actions = {
  addNewRow({ commit }) {
    commit("setNewOrderLineRow");
  },
  async uploadFile(context, { file, orderId }) {
    return new Promise((resolve, reject) => {
      if (file) {
        let files = new FormData();
        files.append("file", file);
        axios
          .post(`${config.API}/attachment/${orderId}`, files, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => {
            context.commit("setUploadError", null);
            resolve(response);
          })
          .catch(error => {
            context.commit("setUploadError", error);
            reject(error);
          });
      }
    });
  },

  async loadProject(context, id) {
    return new Promise((resolve, reject) => {
      if (id) {
        axios
          .get(`${config.API}/projects/${id}`)
          .then(r => {
            context.commit("setLoading", false);
            context.commit("setCurrentProject", r.data);
            context.commit("setErrorMsg", null);
            resolve(r);
          })
          .catch(e => {
            context.commit("setErrorMsg", e);
            reject(e);
          });
      } else {
        reject("Missing id");
      }
    });
  },

  async getProjects({ commit }) {
    commit("setLoading", true);
    axios
      .get(`${config.API}/projects`)
      .then(r => {
        commit("setLoading", false);
        commit("setProjects", r.data);
        commit("setErrorMsg", null);
      })
      .catch(e => {
        commit("setErrorMsg", e);
      });
  },
  // method for creating a new project
  async addProject({ commit }, project) {
    const response = await axios.post(`${config.API}/projects`, {
      ...project
    });

    commit("newProject", response.data);

    project.name = "";
    project.description = "";
    project.contactDetails = [
      {
        name: "",
        mail: "",
        phone: "",
        address: [
          {
            streetName: "",
            PostalCode: "",
            City: "",
            Country: ""
          }
        ]
      }
    ];
  },

  async updateProject({ commit }, project) {
    const response = await axios.put(`${config.API}/projects/${project.id}`, {
      ...project
    });

    commit("updateProject", response.data);
  },

  async setProject({ commit }, id) {
    commit("setActiveProject", id);
    commit("setContactOptions");
    // adds a recorde of the last project used in the local storage
    localStorage.setItem("activeProject", JSON.stringify(id));
  },

  async updateActiveContactDetails({ commit }, id) {
    if (id) {
      //sets the active contact details with id = id
      commit("setContactDetails", id);
    }
  },

  //Not in use?
  async createNewOrderOnProject({ commit }, project, order) {
    if (project.id) {
      const projectWithNewOrder = project.orders.push(order);
      //new or update order

      `${config.API}/projects/${project.id}`,
        axios
          .put({
            projectWithNewOrder
          })
          .then(r => {
            //commits it to the current active project
            commit("updateProject", r.data);
          });
    }
  },

  async loadOrder(context, id) {
    context.commit("setLoading", true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.API}/orders/${id}`)
        .then(r => {
          context.commit("setLoading", false);
          context.commit("setCurrentOrder", r.data);
          resolve(r);
        })
        .catch(error => {
          context.commit("setErrorMsg", error);
          reject(error);
        });
    });
  },

  async loadOrders(context) {
    return new Promise((resolve, reject) => {
      context.commit("setLoading", true);
      // load dependencies
      axios
        .get(`${config.API}/orders`)
        .then(r => {
          context.commit("setLoading", false);
          // resolve
          context.commit("setOrders", r.data);
          resolve(r);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  async updateCreateCurrentOrder(context, order) {
    return new Promise((resolve, reject) => {
      //update or create new order process started
      if (order && !order.id) {
        // Ensure that is doesn't contain any orderNo reference
        delete order.orderNo;

        axios.get(`${config.API}/status/1`).then(r => {
          order.statusNo = r.data.statusNo;
          order.statusName = r.data.name;

          //Missing ID on order. Creates new order
          axios
            .post(`${config.API}/orders`, {
              ...order
            })
            .then(r => {
              //POST data to order API
              context.commit("setCurrentOrder", r.data);
              context.commit("setErrorMsg");
              resolve(r);
            })
            .catch(error => {
              context.commit("setErrorMsg", error);
              reject(error);
            });
        });
      } else {
        axios
          .put(`${config.API}/orders/${order.id}`, {
            ...order
          })
          .then(() => {
            context.commit("setCurrentOrder", order);
            context.commit("setErrorMsg");
            resolve(order);
          })
          .catch(error => {
            context.commit("setErrorMsg", error);
            reject(error);
          });
      }
    });
  },

  async setCurrentOrderLine(context, orderLine) {
    if (orderLine) {
      let line = orderLine;
      context.commit("setOrderLine", line);
    }
  },
  async setCurrentOrderLineId(context, id) {
    //used to re-set the current order line item based
    context.commit("setEditOrderLineId", id);
  },

  // Moved out and into NEW form (needs to be moved together with the item modal)
  async addItemToOrder(context) {
    return new Promise((resolve, reject) => {
      context.commit("statusItem", "initiated");
      if (state.order.id) {
        // order.orderLines.push(orderLine);
        if (state.orderLine) {
          var item = state.orderLine;
          context.commit("addItemToOrderState", item);
          axios
            .put(`${config.API}/orders/${state.order.id}`, {
              ...state.order
            })
            .then(r => {
              context.commit("statusItem", "added");
              context.commit("statusItem", null);
              context.commit("setCurrentOrder", r.data);
              resolve(r);
            })
            .catch(error => {
              context.commit("statusItem", "Failed: " + error);
              reject(error);
            });
        }
      } else {
        context.commit("statusItem", "Failed: No current order ");
      }
    });
  },

  async loadStatuses(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.API}/status/`)
        .then(r => {
          context.commit("setStatuses", r.data);
          resolve(r);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async loadItems({ commit, getters }) {
    if (!getters.allItems || getters.allItems.length === 0) {
      commit("setLodatingSate", true);
      const response = await axios.get(`${config.API}/items`);

      commit("setLodatingSate", false);
      commit("setItems", response.data);
    }
  },

  // used to set a current item in the order form
  async setNewFormItem({ commit }, id) {
    if (id) {
      await axios
        .get(`${config.API}/items/${id}`)
        .then(function(response) {
          commit("setCurrentItem", response.data);
          commit("setNewItemNameOnOrderLine", response.data.name);
          commit("setErrorMsg");
        })
        .catch(error => {
          commit("setErrorMsg", error);
        });
    } else {
      commit("setCurrentItem", {});
    }
  },

  async getItems(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.API}/items`)
        .then(function(response) {
          // handle success
          context.commit("setItems", response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit("setErrorMsg", error);
          reject(error);
        });
      context.commit("setLoading", false);
      context.commit("setErrorMsg");
    });
  },

  async addItem({ commit }, item) {
    // TODO:
    // Add check if item.id exists, then PUT insted of POST method.
    if (item.id) {
      await axios
        .put(`${config.API}/items/${item.id}`, {
          ...item
        })
        .then(r => {
          commit("updateItem", r.data);
        });
    } else {
      await axios
        .post(`${config.API}/items`, {
          ...item
        })
        .then(r => {
          //Post data to API items
          commit("newItem", r.data);
          item = "";
        });
    }
  },

  async deleteItem({ commit }, itemId) {
    axios
      .delete(`${config.API}/items/${itemId}`)
      .then(() => {
        //handle request
        commit("removeItem", itemId);
        commit("showModal");
      })
      .catch(error => {
        commit("setDeleteErroMsg", error);
      });
  },

  async loadPolishTypeForItem({ commit, getters }, itemId) {
    return new Promise((resolve, reject) => {
      if (!getters.getPolishTypesForItem[itemId]) {
        axios
          .get(`${config.API}/items/${itemId}`)
          .then(response => {
            var polishTypesOptions = response.data.polishType.map(x => {
              return { value: x.name, text: x.name };
            });
            commit("setPolishTypesForItems", { itemId, polishTypesOptions });
            resolve({ itemId, polishTypesOptions });
          })
          .catch(error => {
            reject(error);
          });
      } else {
        resolve();
      }
    });
  },

  async setSelectedItem({ commit }, id) {
    if (id) {
      commit("setLoading", true);

      await axios
        .get(`${config.API}/items/${id}`)
        .then(function(response) {
          commit("setItem", response.data);
        })
        .catch(error => {
          commit("setErrorMsg", error);
        });
      commit("setLoading", false);
      commit("setErrorMsg");
    } else {
      //Missing id. Can't load item for id
      commit("setItem", {});
    }
  }
};

const mutations = {
  setUploadError: (state, error) => (state.uploadError = error),
  setLoading: (state, isLoading) => (state.loading = isLoading),
  setProjects: (state, projects) => (state.projects = projects),
  newProject: (state, project) => state.projects.unshift(project),
  setCurrentProject: (state, project) => (state.project = project),
  setActiveProject: (state, id) => {
    state.project = state.projects.find(function(obj) {
      return obj.id === id;
    });
  },
  updateProject: (state, project) =>
    state.projects.map(x => (x.id == project.id ? project : x)),
  setContactOptions: state => {
    if (state.project) {
      state.contactOptions = state.project.contactDetails.map(x => {
        return { value: x.id, text: x.name };
      });
    }
  },
  setContactDetails: (state, id) =>
    (state.contactDetails =
      state.project.contactDetails[
        state.project.contactDetails.findIndex(i => i.id === id)
      ]),
  setOrderStatus: (state, status) => (state.orderStatus = status),
  setCurrentItem: (state, item) => (state.currentItem = item),
  setOrders: (state, orders) =>
    (state.orders = orders.sort(
      (a, b) => parseInt(b.orderNo) - parseInt(a.orderNo)
    )),
  setOrderLine: (state, orderLine) => (state.orderLine = orderLine),
  setNewItemNameOnOrderLine: (state, name) => (state.orderLine.name = name),
  removeOrderLineFromOrder: (state, index) => {
    if (index === 0) {
      return state.order.orderLines.splice(0, 1);
    }
    return state.order.orderLines.splice(index, 1);
  },
  addItemToOrderState: (state, item) => {
    state.order.orderLines.push(item);
  },
  setOrderDescription: (state, description) =>
    (state.order.description = description),
  setOrderLineItemWidth: (state, width) =>
    (state.orderLine.orderLineItem.width = width),
  setLodatingSate: (state, loading) => (state.loadingItemsOnOrder = loading),
  setCurrentOrder: (state, order) => (state.order = order),
  addItem: (state, item) => state.order.orderLines.push(item),
  statusItem: (state, status) => (state.status.item = status),
  setEditOrderLineId: (state, id) => (state.editOrderLineId = id),
  setItems: (state, items) => (state.items = items),
  setItem: (state, item) => (state.selectedItem = item),
  setStatuses: (state, statuses) => {
    if (statuses) {
      state.statuses = statuses
        .sort((a, b) => parseInt(a.statusNo) - parseInt(b.statusNo))
        .map(x => {
          return { value: x.statusNo, text: x.name };
        });
    }
  },
  newItem: (state, item) => state.items.unshift(item),
  updateItem: (state, item) =>
    (state.items[state.items.findIndex(i => i.id === item.id)] = item),
  removeItem: (state, itemId) =>
    (state.items = state.items.filter(i => i.id !== itemId)),
  setErrorMsg(state, msg) {
    if (msg) {
      state.error = msg;
    } else {
      state.error = null;
    }
  },
  setDeleteErroMsg(state, error) {
    state.deleteItem.showModal = true;
    state.deleteItem.isDelete = false;
    state.deleteItem.msg = error;
  },
  setNewOrderLineRow(state) {
    var lineNumber = state.order.orderLines.length;
    state.order.orderLines.push({
      lineNumber: lineNumber,
      polishType: null,
      name: null,
      qty: 1,
      height: null,
      width: null,
      sqrMeter: null,
      description: "",
      specialTxt: null,
      attachmentUrl: null,
      attachmentUrl2: null,
      attachmentUrl3: null,
      attachmentUrl4: null
    });
  },
  showModal: state => (state.deleteItem.showModal = true),
  setPolishTypesForItems(state, { itemId, polishTypesOptions }) {
    state.polishTypePerItem[itemId] = polishTypesOptions;
  },
  setPolishTypesLoading: (state, loading) =>
    (state.polishTypesLoading = loading)
};

export default {
  state,
  getters,
  actions,
  mutations
};
