import axios from "axios";
import config from "@/store/config.js";

const state = {
  isCompaniesLoading: true,
  companies: [],
  errorCompanies: null
};

const getters = {
  getCompanies: state => state.companies,
  getCompanyLoadingState: state => state.isCompaniesLoading
};

const actions = {
  async fetchCompanies(context) {
    context.commit("setLoadingCompanies", true);
    return new Promise((resolve, reject) => {
      var url = `${config.API}/Companies`;
      axios
        .get(url)
        .then(r => {
          context.commit("setCompanies", r.data);
          context.commit("setLoadingCompanies", false);
          resolve(r);
        })
        .catch(e => {
          context.commit("setError", e);
          reject(e);
        });
    });
  },
  async postNewCompanies(context, company) {
    return new Promise((resolve, reject) => {
      if (company) {
        axios
          .post(`${config.API}/Company`, {
            ...company
          })
          .then(r => {
            //Post data to company API endpoint
            context.commit("newItem", r.data);
            resolve(r);
          })
          .catch(error => {
            reject(error);
          });
      }
      resolve("Failed to add new company!");
    });
  }
};

const mutations = {
  setLoadingCompanies: (state, isLoading) =>
    (state.isCompaniesLoading = isLoading),
  setCompanies: (state, companiesData) => (state.companies = companiesData),
  addNewCompany: (state, company) => {
    state.order.orderLines.push(company);
  },
  setError: (state, error) => (state.errorCompanies = error)
};

export default {
  state,
  getters,
  actions,
  mutations
};
